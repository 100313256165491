import { render, staticRenderFns } from "./LogrosAprendizaje.vue?vue&type=template&id=0d8f2620"
import script from "./LogrosAprendizaje.vue?vue&type=script&lang=js"
export * from "./LogrosAprendizaje.vue?vue&type=script&lang=js"
import style0 from "./LogrosAprendizaje.vue?vue&type=style&index=0&id=0d8f2620&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports