<template>
  <b-overlay
    v-if="$can('read', 'contenidos')"
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-alert
      v-if="bloquesAlert"
      variant="info"
      show
      class="text-center mt-1 mr-25 ml-25 pb-2 pt-2"
    >
      El día no tiene horas asignadas.
    </b-alert>
    <b-table
      v-else
      small
      striped
      hover
      noCollapse
      bordered
      responsive
      sticky-header
      class="mt-1 mb-2 rounded"
      :style="cargando ? 'min-height: 600px !important;' : 'overflow-x:hidden; min-height: 600px !important; '"
      :tbody-tr-class="rowClass"
      :fields="fields"
      :items="items"
    >
      <!-- Cargando -->
      <template #table-busy>
        <div class="text-center text-danger my-1">
          <spinner />
        </div>
      </template>

      <template #cell(num_bloque)="data">
        {{ data.item.Bloque }}
      </template>

      <template #cell(bloque)="data">
        <div :class="`${getUser.id_rol !== 2 ? 'text-dark text-center' : 'text-center'}`">
          {{ formatHoraInput(data.item.hora_inicio) }} <br> {{ formatHoraInput(data.item.hora_termino) }}
        </div>
      </template>

      <template #cell(asignatura)="data">
        <p :class="`${getUser.id_rol !== 2 ? 'text-dark mt-1 mb-25' : 'mt-1'}`">
          {{ data.item.nombre_asignatura }}
        </p>
        <!-- <br> -->
        <p class="text-secundary">
          {{ nombreDocente(data.item.nombre+' '+data.item.primer_apellido+' '+data.item.segundo_apellido) }}
        </p>
      </template>

      <template #cell(contenido)="data">
        <col-contenido
          :item.sync="data.item"
          :field.sync="data.field"
          :fechaSelected="fechaSelected"
        />
      </template>

    </b-table>
  </b-overlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BModal, VBModal, BAlert, BFormTextarea,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store/index'

import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'

// COMPONENTES HIJOS
import colContenido from './colContenido.vue'

// MIXINS
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BButtonGroup,
    BFormRadioGroup,
    BAvatar,
    BOverlay,
    BCard,
    BTab,
    BTabs,
    BTable,
    BCardText,
    BModal,
    BFormTextarea,
    VBModal,
    BAlert,

    // COMPONENTES RECICLADOS
    spinner,
    colContenido,
  },
  mixins: [formatos],
  directives: {
    Ripple,
  },
  data() {
    return {
      // Alerts & Reload
      cargando: true,
      bloquesAlert: false,

      // Data
      contenido: null,
      items: [],

      // Fields Tabla
      fields: [],
      fieldsDefault: [
        {
          key: 'bloque',
          label: 'Horas',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'asignatura',
          label: 'Asignaturas',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'contenido',
          label: 'Registro de Actividades',
          variant: 'light',
          thStyle: {
            width: '70% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getContenidos: 'contenidos/getContenidos',
      getLibroSelected: 'libros/getLibroSelected',
      getUser: 'auth/user',
    }),
  },
  props: {
    fechaSelected: {
      type: String,
      required: true,
    }
  },
  watch: {
    getContenidos(val) {
      this.items = []
      this.items = val
      this.bloquesAlert = val.length === 0
      this.fields = this.fieldsDefault
      this.cargando = false
    },
    fechaSelected(fechaSelected) {
      this.cargando = true
      this.cargarContenidos(this.getLibroSelected.id)
    },
    getLibroSelected(val) {
      this.cargando = true
      this.cargarContenidos(this.getLibroSelected.id)
    },
  },
  mounted() {
    this.cargarContenidos(this.getLibroSelected.id)
  },
  methods: {
    ...mapActions({
      fetchContenidos: 'contenidos/fetchContenidos',
    }),
    nombreDocente(nombreCompleto) {
      let nombre
      if (this.getUser.id_rol !== 2) {
        nombre = nombreCompleto
      }
      return nombre
    },
    cargarContenidos(id_curso) {
      const data = {
        id_curso,
        fecha: this.fechaSelected,
      }
      this.fetchContenidos(data)
    },

    rowClass(item, type) {
      let colorClass = 'table-secondary-dark'
      if (this.getUser.id_rol === 2) {
        if (this.$store.state.appConfig.layout.skin === 'dark') {
          colorClass = 'table-secondary-dark'
        } else {
          colorClass = 'table-secondary'
        }
      }

      if (!item || type !== 'row') { return }
      // eslint-disable-next-line consistent-return
      if (item.id_persona_rol !== this.getUser.id_persona_rol) { return colorClass }
    },
  },
}
</script>
